.PersonalAccount {
    color: #ffffff;
    margin-top: 40px;
    padding-bottom: 20px;
}

.ManagePassword, .ManageProfilePicture {
    background-color: #282d49;
    width: 500px;
    margin: auto;
    margin-top: 20px;
    padding: 20px 10px 40px;
    border-radius: 20px;
}

.ManagePassword h1, .ManageProfilePicture h1, .Signout h1 {
    font-size: 20px;
}

.Signout {
    background-color: #282d49;
    width: 500px;
    margin: auto;
    margin-top: 20px;
    padding: 10px 10px 20px;
    border-radius: 20px;
}

.Signout button {
    background-color: rgb(223, 230, 255);
    color: #1c1e27;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding: 8px 40px;
    border: none;
    border-radius: 30px;
}

.Signout button:hover {
    cursor: pointer;
}

.PictureUpload input {
    background-color: rgb(223, 230, 255);
    color: #17181d;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    padding: 6px 20px;
    border: none;
    border-radius: 30px;
}

.profilePic::-webkit-file-upload-button {
    background-color: rgb(75, 91, 151);
    color: #eaeeff;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    padding: 8px 30px;
    border: none;
    border-radius: 30px;
}

.profilePic::-webkit-file-upload-button:hover {
    background-color: rgb(101, 118, 180);
    transition: all 0.3s ease;
    cursor: pointer;
}

.CurrentSelectedPicture {
    margin-top: 15px;
}

.CurrentSelectedPicture img {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #A4A9B5;
}

.ProfilePictureButton {
    margin-top: 10px;
}

.ProfilePictureButton button {
    background-color: #CFD4E7;
    color: #17181d;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    padding: 6px 30px;
    border: none;
    border-radius: 30px;
}

.ProfilePictureButton button:hover {
    background-color: #c3c8da;
    transition: all 0.3s ease;
    cursor: pointer;
}

.UploadPictureError p {
    color: #ff8c8c;
    font-size: 15px;
    margin-bottom: 0;
}

.UploadPictureSuccess p {
    color: #7dff99;
    font-size: 15px;
    margin-bottom: 0;
}

.OldPassword p, .NewPassword p {
    font-size: 16px;
    margin: 10px 0 5px;
}

#NewPasswordRequirements {
    font-size: 11px;
    font-style: italic;
    margin: 10px 0 5px;
}

.ManagePassword input {
    font-family: 'Roboto', sans-serif;
    text-align: left;
    color: #ffffff;
    background-color: #8284A5;
    padding: 8px 30px;
    height: 14px !important;
    width: 240px;
    outline: none;
    border: solid 1px #b8bad4;
    font-size: 14px;
    resize: none;
    border-radius: 30px;
}

.ManagePassword input::placeholder {
    color: #b6b6b6;
    font-weight: normal;
}

.PasswordNotMatching p, .PoorPasswordMsg p, .OldPasswordIncorrectMsg p {
    font-size: 16px;
    color: #ff6f6f;
}

.EmptyFieldMsg p {
    font-size: 16px;
    color: #4de4ff;
}

.SuccessfulPasswordChange p {
    font-size: 16px;
    color: rgb(85, 255, 170);
}

.ChangePasswordButton {
    margin-top: 20px;
}

.ChangePasswordButton button {
    background-color: #CFD4E7;
    color: #17181d;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding: 10px 30px;
    border: none;
    border-radius: 30px;
    width: 250px;
}

.ChangePasswordButton button:hover {
    background-color: #c3c8da;
    transition: all 0.3s ease;
    cursor: pointer;
}

@media screen and (max-width: 580px) {
    .Signout, .ManagePassword, .ManageProfilePicture {
        width: 85vw;
        padding: 10px 10px 20px;
    }

    .ManagePassword h1, .ManageProfilePicture h1, .Signout h1 {
        font-size: 18px;
    }

    .ManagePassword input {
        width: 60%;
    }

    .OldPassword p, .NewPassword p {
        font-size: 14px;
    }

    .UploadPictureSuccess p, .UploadPictureError p, .PasswordNotMatching p, .PoorPasswordMsg p, .OldPasswordIncorrectMsg p, .EmptyFieldMsg p, .SuccessfulPasswordChange p {
        font-size: 14px;
    }
}