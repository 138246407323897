.App {
	background-color: #131624;
	min-height: 100vh;
	text-align: center;
	font-family: "Roboto", sans-serif;
}

.HomepageHeader {
	margin: 0;
	background-color: #212435;
	padding-top: 12px;
	padding-bottom: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Title,
.AccountInfoWrap {
	width: 30%;
}

.AccountInfo {
	display: flex;
	align-items: center;
	float: right;
}

.AlignTitle {
	display: flex;
	align-items: center;
	margin-left: 30px;
}

.AlignTitle img {
	height: 36px;
	width: auto;
}

.SearchBar {
	width: 40%;
}

.Title {
	text-align: left;
}

.Title span {
	padding-left: 10px;
	font-weight: bold;
	font-size: 26px;
}

.SearchBar {
	text-align: center;
}

.Align {
	display: flex;
	align-items: center;
	font-weight: normal;
	font-size: 17px;
	padding-right: 50px;
}

.Align img {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	border: 2px solid #a4a9b5;
	margin-right: 10px;
	object-fit: cover;
}

.Notifications {
	display: flex;
	margin-right: 20px;
	margin-left: 20px;
}

.NotificationsCounter {
	color: #ffffff;
	font-size: 14px;
}

.Notifications img {
	height: 26px;
	width: auto;
}

.Notifications img:hover {
	cursor: pointer;
}

.HomepageHeader p {
	color: #ffffff;
	text-decoration: none;
	margin: 0;
}

.HomepageHeader input {
	font-family: "Roboto", sans-serif;
	text-align: center;
	color: #ffffff;
	background-color: #3c4056;
	padding: 6px 30px;
	height: 24px !important;
	width: 80%;
	border: none;
	outline: none;
	font-size: 18px;
	resize: none;
	border-radius: 30px;
}

.HomepageHeader input::placeholder {
	color: #d3d3d3;
	font-weight: normal;
}

.NotificationList {
	position: absolute;
	background-color: #2c3252;
	right: 10px;
	color: #ffffff;
	width: 400px;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
	box-shadow: inset 0 0 5px rgb(6, 11, 22);
	padding-bottom: 16px;
}

.NotificationList h1 {
	font-size: 22px;
}

.NotificationMessage p {
	font-size: 16px;
	margin-bottom: 4px;
}

.NotificationMessage:hover {
	font-weight: bold;
}

#NotificationDate {
	font-size: 11px;
	color: #dfdfdf;
	margin: 0;
	font-style: italic;
}

@media screen and (max-width: 1050px) {
	.Title span {
		font-size: 20px;
	}

	.Title, .Account {
		width: 25%;
	}

	.SearchBar {
		width: 50%;
	}
}

@media screen and (max-width: 850px) {
	.Title span, .Align span {
		display: none;
	}
}

@media screen and (max-width: 720px) {
	.Align {
		padding-right: 10px;
	}
}

@media screen and (max-width: 480px) {
	.AlignTitle {
		margin-left: 20px;
	}

	.Align {
		padding-right: 0px;
	}

	.Title {
		width: 18%;
	}

	.Account {
		width: 30%;
	}

	.SearchBar {
		width: 52%;
	}

	.Notifications img {
		height: 22px;
	}

	.HomepageHeader input {
		font-size: 16px;
	}

	.Notifications {
		margin-right: 5px;
	}

	.NotificationList {
		width: 100vw;
		right: 0;
	}
}