.PersonalPosts {
    color: #ffffff;
    padding-bottom: 20px;
}

.CreatePost {
    margin: auto;
    background-color: #2E3643;
    width: 500px;
    border-radius: 15px;
    padding: 20px 30px;
    margin-top: 40px;
    text-align: left;
}

.CreatePost h1 {
    font-size: 20px;
    font-weight: normal;
    margin: 0;
}

.PostPicture {
    margin-top: 10px;
}

.PostPicture input {
    font-size: 14px;
}

.postPic::-webkit-file-upload-button {
    background-color: #4c66aa;
    color: #eaeeff;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    padding: 8px 30px;
    border: none;
    border-radius: 30px;
}

.postPic::-webkit-file-upload-button:hover {
    background-color: rgb(73, 92, 158);
    transition: all 0.3s ease;
    cursor: pointer;
}

.PostPicturePreview {
    margin-top: 10px;
    text-align: center;
}

.Image {
    margin-top: 40px;
    text-align: center;
}

.PostPicturePreview img, .Image img {
    width: 400px;
    height: auto;
}

.PostText {
    margin-top: 10px;
}

.PostText textarea {
    text-align: left;
    color: #EAEAEA;
    background-color: #696b8b;
    padding: 16px 16px 2px;
    border: none;
    height: 60px;
    width: 95%;
    outline: none;
    font-size: 16px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: normal;
    resize: none;
    border-radius: 15px;
}

.PostText textarea::placeholder {
    color: #EAEAEA;
    font-weight: normal;
}

.PostButton, .CannotPostButton {
    text-align: center;
    margin-top: 4px;
}

.PostButton button {
    background-color: #4c66aa;
    color: #ffffff;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    padding: 8px 30px;
    border: none;
    border-radius: 30px;
    width: 400px;
}

.PostButton button:hover {
    background-color: #5a76bb;
    transition: all 0.3s ease;
    width: 450px;
    cursor: pointer;
}

.CannotPostButton button {
    background-color: #969aa3;
    color: #ffffff;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    padding: 8px 30px;
    border: none;
    border-radius: 30px;
    width: 400px;
}

.CannotPostButton button {
    cursor: pointer;
}

.MakeComment {
    margin-top: 10px;
}

.MakeComment textarea {
    text-align: left;
    color: #EAEAEA;
    background-color: #545569;
    padding: 10px 24px 0px;
    height: 28px;
    border: none;
    width: 414px;
    outline: none;
    font-size: 16px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: normal;
    resize: none;
    border-radius: 30px;
}

.MakeComment textarea::placeholder {
    color: #EAEAEA;
    font-weight: normal;
}

/**********************************
    Styling for loading posts
***********************************/

.NoPost {
    margin: auto;
    background-color: #2E3643;
    width: 500px;
    border-radius: 30px;
    padding: 1px 30px;
    margin-top: 20px;
}

.Posts {
    padding-bottom: 40px;
}

.Post {
    margin: auto;
    background-color: #2E3643;
    width: 500px;
    border-radius: 15px;
    padding: 20px 30px 20px;
    margin-top: 20px;
}

.Post .DeletePost {
    text-align: right;
    margin-bottom: 8px;
}

.Post .DeletePost button {
    padding: 2px 6px 3px;
    text-align: center;
    border-radius: 5px;
    font-size: 12px;
    background-color: rgba(158, 31, 31, 0.87);
    color: #ffffff;
    border: 1px solid #ca7d7d;
}

.Post .DeletePost button:hover {
    cursor: pointer;
    background-color: rgba(124, 22, 22, 0.87);
}

.ConfirmDeletePost {
    margin: auto;
    background-color: #455164;
    width: 350;
    border-radius: 15px;
    padding: 10px 20px 10px;
    text-align: center;
    margin-top: 10px;
}

.ConfirmDeletePost p {
    font-size: 14px;
    margin: 0 0 2px;
}

.Post .DeletePost .ConfirmDeletePost button {
    padding: 2px 20px 3px;
    text-align: center;
    border-radius: 5px;
    font-size: 12px;
    background-color: rgba(158, 31, 31, 0.87);
    color: #ffffff;
    border: 1px solid #ca7d7d;
    margin: 0 5px;
}

#CancelDeletePost {
    background-color:#345d99;
    border: 1px solid #6193df;
}

.Post .PostHeader .PostUser {
    float: left;
    font-size: 16px;
    font-weight: bold;
}

.Post .PostHeader .PostDate {
    float: right;
    font-size: 13px;
}

.Post .Text {
    text-align: left;
    font-size: 18px;
    margin-top: 30px;
    border-bottom: 1px solid #ffffff;
}

.Post .Likes {
    text-align: left;
    font-size: 14px;
    border-bottom: 1px solid #ffffff;
}

.Comment {
    margin: auto;
    background-color: #4E525F;
    width: 380px;
    border-radius: 40px;
    padding: 12px 40px;
    margin-top: 10px;
}

.Comment .CommentHeader .CommentUser {
    float: left;
    font-size: 14px;
}

.Comment .CommentHeader .CommentDate {
    float: right;
    font-size: 11px;
}

.Comment p {
    color: #ffffff;
    font-size: 14px;
    text-align: left;
    margin: 20px 0px 0px;
}

@media screen and (max-width: 600px) {
    .CreatePost {
        width: 80vw;
        padding: 20px 20px;
    }

    .CreatePost h1 {
        font-size: 18px;
    }

    .postPic::-webkit-file-upload-button {
        padding: 6px 20px;
    }

    .PostPicturePreview img, .Image img {
        width: 100%;
    }

    .PostText textarea {
        width: 90%;
    }

    .PostButton button, .CannotPostButton button {
        width: 100%;
    }

    .NoPost, .Post {
        width: 75vw;
    }

    .MakeComment textarea {
        width: 80%;
    }

    .Comment {
        width: 75%;
        padding: 12px 30px;
    }
}
