.Profile {
    background-color: #131624;
}

.ProfileNotFound p {
    color: #ffffff;
}

.UserInfo {
    margin: auto;
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 2px solid #454d68;
    margin-bottom: 20px;
    width: 40%;
    max-width: 1000px;
}

.UserInfo img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 3px solid #A4A9B5;
    object-fit: cover;
}

.UserInfo p {
    color: #ffffff;
    font-family: Roboto, 'sans-serif';
    font-size: 22px;
    font-weight: normal;
    margin: 0;
    padding-top: 10px;
}

#UserFollowerCount {
    font-size: 20px;
    font-weight: bold;
}

#UserFollowersText {
    font-size: 13px;
    padding: 0 0 5px;
}

.UserInfo button {
    background-color: #384077;
    color: #ffffff;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    padding: 4px 16px;
    border: none;
    border-radius: 10px;
    margin-top: 5px;
    border: 1px solid #8e93b4;
}

.UserInfo button:hover {
    transition: all 0.3s ease;
    cursor: pointer;
}

.ProfileSections {
    margin-top: 10px;
    margin: auto;
}

.ProfileSections button {
    background-color: #50546D;
    color: #ffffff;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding: 6px 24px;
    border: none;
    border-radius: 30px;
    margin-left: 8px;
    margin-right: 8px;
}

.ProfileSections button:hover {
    transition: all 0.3s ease;
    cursor: pointer;
    background-color: #8e93b4;
}

@media screen and (max-width: 480px) {
    .UserInfo {
        width: 80%;
    }
    
    .UserInfo img {
        width: 100px;
        height: 100px;
        border: 2px solid #A4A9B5;
    }
    
    .UserInfo p {
        font-size: 18px;
    }
    
    #UserFollowerCount {
        font-size: 16px;
    }
    
    #UserFollowersText {
        font-size: 12px;
    }
    
    .ProfileSections {
        width: 360px;
    }

    .ProfileSections button {
        font-size: 14px;
        padding: 6px 12px;
        margin-left: 4px;
        margin-right: 4px;
        margin-bottom: 4px;
    }
}