.PersonalLiveStream {
	color: #ffffff;
	padding-bottom: 10%;
}

.PersonalLiveStream__container__title {
	font-size: 1.5rem;
	font-weight: bold;
	margin-bottom: 1rem;
}

.PersonalLiveStream__container {
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: left;
	border-radius: 0.5rem;
	color: #ffffff;
	padding: 1rem;
	width: 50%;
	margin: 3% auto;
}

.PersonalLiveStream__container__video {
    backdrop-filter: blur(20px);
    border-radius: 5px;
}

.PersonalLiveStream__container__video__video {
	width: 100%;
	height: 100%;
	border-radius: 5px;
	background-color: #000000;
    padding: 10px;
    box-sizing: border-box;
}

.PersonalLiveStream__container__actions__button, .PersonalLiveStream__container__actions__stopbutton, .PersonalLiveStream__container__actions__infobutton {
    border: none;
    /* Purple */
    background-color: #cc74b2;
    color: #ffffff;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    width: auto;
}

.PersonalLiveStream__container__actions__infobutton {
    background-color: #ffffff;
    color: #000000;
}

.PersonalLiveStream__container__actions__infobutton:hover {
    background-color: #c7c7c7;
    color: #000000;
}

.PersonalLiveStream__container__actions__stopbutton {
    background-color: #ff5c5c;
}

.PersonalLiveStream__container__actions__stopbutton:hover {
    background-color: #fd3838;
}

.PersonalLiveStream__container__actions__button:hover {
    background-color: #c955a6;
}

.PersonalLiveStream__container__actions {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.PersonalLiveStream__container__description {
    margin-top: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
}

.PersonalLiveStream__container__actions__textarea {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: rgb(18, 21, 36);
    color: white;
    padding: 10px;
    box-sizing: border-box;
    outline: none;
    border: none;
}

.PersonalLiveStream__container__description__subheader {
    font-size: 0.8rem;
    font-weight: bold;
    text-align: right;
    color:rgb(177, 177, 177);
    text-transform: uppercase;
    line-height: 0;
    padding: 0;
}

.PersonalLiveStream__container__description__header {
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    color:white;
    text-transform: uppercase;
    line-height: 0;
    padding: 0;
    margin-bottom: 2rem;
}

.PersonalLiveStream__container__description__title {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
}

.PersonalLiveStream__container__description__title > *:last-child {
    text-align: right;
}

.PersonalLiveStream__container__description__streamid {
    font-size: 0.8rem;
    line-height: 0;
}

.PersonalLiveStream__container__description__streamid__status {
    float: right;
    text-transform: uppercase;
}

@media screen and (max-width: 768px) {
    .PersonalLiveStream__container {
        width: 90%;
    }
}