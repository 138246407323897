.PastLiveStreams {
    color: #ffffff;
}

.PastLiveStreams__card {
    background-color: #000000;
    border-radius: 5px;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
}

.PastLiveStreams__container {
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: left;
	border-radius: 0.5rem;
	color: #ffffff;
	padding: 1rem;
	width: 50%;
	margin: 1% auto;
    margin-bottom: 3%;
}

.PastLiveStreams__header {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: left;
}