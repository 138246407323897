.Signup, .Login, .ResetPasswordPage {
  box-shadow: inset 0 0 10px rgb(6, 11, 22);
  width: 400px;
  margin: auto;
  margin-top: 50px;
  padding: 30px 20px 40px;
  border-radius: 20px;
  background-color: #171a29;
}

.Signup p, .Login p, .ResetPasswordPage p {
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.InputBar {
  width: 310px;
  margin: auto;
  padding-top: 4px;
}

.InputBar p {
  color: #ffffff;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  margin-bottom: 8px;
}

#passwordRequirements {
  font-size: 12px;
  font-style: italic;
}

.InputBar input {
  font-family: 'Roboto', sans-serif;
  text-align: left;
  color: #ffffff;
  background-color: #8284A5;
  padding: 7px 30px;
  height: 20px !important;
  width: 250px;
  outline: none;
  border: solid 1px #aaacca;
  font-size: 16px;
  resize: none;
  border-radius: 30px;
}

.InputBar input::placeholder {
  color: #d3d3d3;
  font-weight: normal;
}

.ChangeFromSignupToSignin p, .ForgetPassword p, .BackToLoginPage p {
  font-size: 16px;
  font-weight: normal;
  text-decoration: underline;
  margin-bottom: 0;
}

.ChangeFromSignupToSignin p:hover, .ForgetPassword p:hover, .BackToLoginPage p:hover {
  cursor: pointer;
}

.SignupButton, .SigninButton, .ResetPasswordButton {
  margin-top: 16px;
}

.SignupButton button, .SigninButton button, .ResetPasswordButton button {
  background-color: #CFD4E7;
  color: #272830;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  padding: 8px 30px;
  border: none;
  border-radius: 30px;
  width: 310px;
}

.SignupButton button:hover, .SigninButton button:hover, .ResetPasswordButton button:hover {
  background-color: #e9edfa;
  transition: all 0.2s ease;
  cursor: pointer;
}

.AccountCreatedMessage p {
  color: #74fa91;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
}

.AccountAlreadyCreatedMessage p, .IncorrectLoginMessage p, .PoorPasswordMessage p {
  color: #ff8670;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  padding-top: 20px;
}

.EmptyFieldsMessage p, .ResetPasswordEmailMessage p {
  color: #70e2ff;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  padding-top: 20px;
}


/* Loading Animation CSS Animation taken from https://loading.io/css/ */
.LoadingAnimation {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 25px;
}

.LoadingAnimation div {
  position: absolute;
  top: 20px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.LoadingAnimation div:nth-child(1) {
  left: 6px;
  animation: LoadingAnimation1 0.6s infinite;
}

.LoadingAnimation div:nth-child(2) {
  left: 6px;
  animation: LoadingAnimation2 0.6s infinite;
}

.LoadingAnimation div:nth-child(3) {
  left: 24px;
  animation: LoadingAnimation2 0.6s infinite;
}

.LoadingAnimation div:nth-child(4) {
  left: 42px;
  animation: LoadingAnimation3 0.6s infinite;
}

@keyframes LoadingAnimation1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes LoadingAnimation3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes LoadingAnimation2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(18px, 0);
  }
}

@media screen and (max-width: 480px) {
  .Signup, .Login, .ResetPasswordPage {
    width: 80vw;
    margin-top: 30px;
  }

  .Signup, .Login p, .ResetPasswordPage p {
    font-size: 20px;
  }

  .InputBar {
    width: 80vw;
  }

  .InputBar p {
    font-size: 16px;
    text-align: center;
  }

  .InputBar input {
    width: 50vw;
  }

  .ChangeFromSignupToSignin p, .BackToLoginPage p, .ForgetPassword p {
    font-size: 16px;
  }

  .SignupButton button, .SigninButton button, .ResetPasswordButton button {
    width: 65vw;
  }

  .AccountAlreadyCreatedMessage p, .IncorrectLoginMessage p, .PoorPasswordMessage p,
  .EmptyFieldsMessage p, .AccountCreatedMessage p, .ResetPasswordEmailMessage p {
    font-size: 16px;
  }
}