.ForgetPasswordPage {
    box-shadow: inset 0 0 10px rgb(6, 11, 22);
    width: 400px;
    margin: auto;
    margin-top: 50px;
    padding: 10px 20px 40px;
    border-radius: 20px;
    background-color: #171a29;
}

.ForgetPasswordPage p {
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.NewPassword1, .NewPassword2 {
    width: 310px;
    margin: auto;
    padding-top: 4px;
}

.NewPassword1 p, .NewPassword2 p {
    color: #ffffff;
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    margin-bottom: 8px;
}

.NewPassword1 input, .NewPassword2 input {
    font-family: 'Roboto', sans-serif;
    text-align: left;
    color: #ffffff;
    background-color: #8284A5;
    padding: 7px 30px;
    height: 20px !important;
    width: 250px;
    outline: none;
    border: solid 1px #aaacca;
    font-size: 16px;
    resize: none;
    border-radius: 30px;
}

.NewPassword1 input::placeholder, .NewPassword2 input::placeholder {
    color: #d3d3d3;
    font-weight: normal;
}

#newPasswordRequirements {
    font-size: 12px;
    font-style: italic;
    font-weight: normal;
}

.ConfirmNewPassword {
    margin-top: 16px;
}

.ConfirmNewPassword button {
    background-color: #CFD4E7;
    color: #272830;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    padding: 8px 30px;
    border: none;
    border-radius: 30px;
    width: 310px;
}

.ConfirmNewPassword button:hover {
    background-color: #e9edfa;
    transition: all 0.2s ease;
    cursor: pointer;
}

.NonMatchingPasswords p, .PoorPasswordMessage p, .MalformedToken p, .NetworkError p {
    color: #ff9885;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    padding-top: 10px;
}

.MalformedToken p, .NetworkError p {
    padding-top: 20px;
}

.EmptyFieldsMessage p {
    color: #70e2ff;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    padding-top: 10px;
}

.PasswordResetSuccess p {
    color: #74fa91;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    padding-top: 20px;
}

.PasswordResetSuccess .BackToLogin p {
    color: #ffffff;
    text-decoration: underline;
}

.PasswordResetSuccess .BackToLogin p:hover {
    cursor: pointer;
}

/* Loading Animation CSS Animation taken from https://loading.io/css/ */
.LoadingAnimation {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 25px;
}

.LoadingAnimation div {
    position: absolute;
    top: 20px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.LoadingAnimation div:nth-child(1) {
    left: 6px;
    animation: LoadingAnimation1 0.6s infinite;
}

.LoadingAnimation div:nth-child(2) {
    left: 6px;
    animation: LoadingAnimation2 0.6s infinite;
}

.LoadingAnimation div:nth-child(3) {
    left: 24px;
    animation: LoadingAnimation2 0.6s infinite;
}

.LoadingAnimation div:nth-child(4) {
    left: 42px;
    animation: LoadingAnimation3 0.6s infinite;
}

@keyframes LoadingAnimation1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes LoadingAnimation3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes LoadingAnimation2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(18px, 0);
    }
}

@media screen and (max-width: 480px) {
    .ForgetPasswordPage {
        width: 80vw;
        margin-top: 30px;
    }

    .ForgetPasswordPage p {
        font-size: 20px;
    }

    .NewPassword1, .NewPassword2 {
        width: 80vw;
    }

    .NewPassword1 p, .NewPassword2 p {
        font-size: 16px;
        text-align: center;
    }

    .NewPassword1 input, .NewPassword2 input {
        width: 50vw;
    }

    .PasswordResetSuccess .BackToLogin p {
        font-size: 16px;
    }

    .ConfirmNewPassword button {
        width: 65vw;
        font-size: 16px;
        padding: 10px 30px;
    }

    .EmptyFieldsMessage p, .NonMatchingPasswords p, .PoorPasswordMessage p,
    .MalformedToken p, .NetworkError p, .PasswordResetSuccess p,
    .PasswordResetSuccess .BackToLogin p {
        font-size: 16px;
    }
}