.Home {
    color: #ffffff;
    padding-bottom: 20px;
}

.HorizontalLine {
    border-bottom: 2px solid #ffffff;
    margin: auto;
    width: 70vw;
    padding-top: 30px;
}

.TopStreamers, .FollowedStreamers {
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;
}

.TopStreamers h1, .FollowedStreamers h1 {
    font-size: 20px;
    padding-bottom: 10px;
}

.TopStreamers h1 {
    color: #99DAE9;
}

.FollowedStreamers h1 {
    color: #FFB2B2;
}

.FollowingStreamersContainer, .TopStreamersContainer {
    margin: auto;
    width: 65vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.FollowingStreamer, .TopStreamer {
    width: 150px;
    margin-bottom: 10px;
}

.FollowingStreamer p, .TopStreamer p {
    font-size: 18px;
    margin-bottom: 0;
}

#TopStreamerFollowerCount {
    font-size: 12px;
    color: #b8b8b8;
    font-style: normal;
    margin-top: 2px;
}

.FollowingStreamer img, .TopStreamer img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    border: 3px solid #A4A9B5;
    object-fit: cover;
}

.NotFollowingStreamers, .NoTopStreamers {
    color: white;
    margin-top: 20px;
    background-color: #202742;
    width: 460px;
    margin: auto;
    padding: 5px 10px 5px;
    border-radius: 20px;
}

.NotFollowingStreamers h1, .NoTopStreamers h1 {
    font-size: 16px;
    font-weight: normal;
    color: #ffffff;
    padding: 0;
}

.NotFollowingStreamers p {
    font-size: 14px;
}

.CreditsPage {
    margin-top: 20px;
}

@media screen and (max-width: 480px) {
	.FollowingStreamersContainer, .TopStreamersContainer {
        width: 85vw;
    }
    
    .FollowingStreamer, .TopStreamer {
        width: 50%;
    }

    .FollowingStreamer img, .TopStreamer img {
        width: 25vw;
        height: 25vw;
    }

    .FollowingStreamer p, .TopStreamer p {
        font-size: 16px;
        margin-bottom: 0;
    }
    
    #TopStreamerFollowerCount {
        font-size: 12px;
    }
    
    .NotFollowingStreamers, .NoTopStreamers {
        width: 80vw;
    }

    .HorizontalLine {
        width: 90vw;
    }
}