.PersonalFollowing {
    color: white;
    margin-top: 20px;
    background-color: #202742;
    width: 450px;
    margin: auto;
    padding: 5px 10px 5px;
    margin-top: 30px;
    border-radius: 20px;
}

.FollowingList {
    margin-bottom: 10px;
}

.AlignImageAndFollowing {
	display: flex;
	align-items: center;
	font-weight: normal;
	font-size: 17px;
}

.AlignImageAndFollowing img {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: 2px solid #a4a9b5;
    object-fit: cover;
	margin-right: 10px;
    margin-left: 30px;
}

.FollowingList p {
    font-size: 16px;
}

.FollowingUser {
    margin: auto;
    background-color: #33406d;
    border-radius: 40px;
    width: 280px;
    padding: 8px 30px;
    margin-top: 10px;
}

.FollowingUser button {
    background-color: #293664;
    color: #ffffff;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    padding: 4px 16px;
    border: none;
    border-radius: 10px;
    margin-top: 5px;
    margin-left: 15px;
    border: 1px solid #88acc4;
}

.FollowingUser button:hover {
    cursor: pointer;
}

@media screen and (max-width: 480px) {
    .PersonalFollowing {
        width: 85vw;
    }

    .AlignImageAndFollowing img {
        margin-left: 15px;
    }

    .FollowingUser {
        width: 65vw;
    }
    
}
