.ProfileSearchResults {
    color: #ffffff;
}

.FoundProfilesList {
    margin-top: 20px;
}

.ProfilesFound h1, .NoProfilesFound h1 {
    font-size: 20px;
    margin-bottom: 20px;
}

.NoProfilesFound p {
    font-size: 18px;
}

#LinkToHome {
    font-weight: bold;
    font-size: 20px;
}

.ProfilesFoundContainer {
    margin: auto;
    width: 65vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.CurrentProfile {
    width: 150px;
}

.CurrentProfile p {
    font-size: 18px;
    margin-bottom: 0;
}

#CurrentProfileFollowerCount {
    font-size: 12px;
    color: #b8b8b8;
    font-style: normal;
    margin-top: 2px;
}

.CurrentProfile img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #A4A9B5;
}

@media screen and (max-width: 480px) {
	.ProfilesFoundContainer {
        width: 85vw;
    }
    
    .CurrentProfile {
        width: 50%;
    }

    .CurrentProfile img {
        width: 25vw;
        height: 25vw;
    }

    .CurrentProfile p {
        font-size: 16px;
        margin-bottom: 0;
    }
    
    #CurrentProfileFollowerCount {
        font-size: 12px;
        color: #b8b8b8;
        font-style: normal;
        margin-top: 2px;
    }

    .ProfilesFound h1, .NoProfilesFound h1 {
        font-size: 18px;
    }
    
    .NoProfilesFound p {
        font-size: 18px;
    }
}