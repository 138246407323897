.PersonalProfile {
    background-color: #131624;
}

.PersonalInfo {
    margin: auto;
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 2px solid #454d68;
    margin-bottom: 20px;
    width: 40%;
    max-width: 1000px;
}

.PersonalInfo img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 3px solid #A4A9B5;
    object-fit: cover;
}

.PersonalInfo p {
    color: #ffffff;
    font-family: Roboto, 'sans-serif';
    font-size: 20px;
    font-weight: normal;
    margin: 0;
    padding-top: 10px;
}

#PersonalFollowerCount {
    font-size: 20px;
    font-weight: bold;
}

#PersonalFollowersText {
    font-size: 13px;
    padding: 0;
}

.PersonalProfileSections {
    margin-top: 10px;
}

.PersonalProfileSections button {
    background-color: #50546D;
    color: #ffffff;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding: 6px 24px;
    border: none;
    border-radius: 30px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 4px;
  }

  .PersonalProfileSections button:hover {
    transition: all 0.3s ease;
    cursor: pointer;
    background-color: #8e93b4;
  }

  @media screen and (max-width: 480px) {
    .PersonalInfo {
        width: 80%;
    }
    
    .PersonalInfo img {
        width: 100px;
        height: 100px;
        border: 2px solid #A4A9B5;
    }
    
    .PersonalInfo p {
        font-size: 18px;
    }
    
    #PersonalFollowerCount {
        font-size: 16px;
    }
    
    #PersonalFollowerText {
        font-size: 12px;
    }
    
    .PersonalProfileSections {
        width: 95vw;
        margin: auto;
    }

    .PersonalProfileSections button {
        font-size: 14px;
        padding: 6px 12px;
        margin-left: 4px;
        margin-right: 4px;
        margin-bottom: 4px;
    }
}
