.CreditsPage {
    color: #ffffff;
}

.CodeCredits h2, .MediaCredits h2 {
    font-size: 26px;
    font-weight: normal;
}

.CodeCredits, .MediaCredits {
    box-shadow: inset 0 0 10px rgb(6, 11, 22);
    width: 700px;
    margin: auto;
    padding: 30px 20px 40px;
    border-radius: 20px;
    background-color: #171a29;
}

.CodeCredits {
    margin-top: 50px;
}

.MediaCredits {
    margin-top: 20px;
}

.CodeCredits a, .MediaCredits a {
    text-decoration: none;
    color: #33f1ff;
}

@media screen and (max-width: 780px) {
    .CodeCredits, .MediaCredits {
        width: 90vw;
    }
}