.App {
  background-color: #131624;
  min-height: 100vh;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.Header {
  margin: auto;
  background-color: #212435;
  padding-top: 20px;
  padding-bottom: 20px;
}

.AlignAppHeader {
	display: flex;
	align-items: center;
  justify-content: center;
}

.AlignAppHeader span {
  color: #ffffff;
  font-weight: bold;
  font-size: 32px;
  margin-left: 10px;
}

.AlignAppHeader img {
  height: 45px;
	width: auto; 
}


@media screen and (max-width: 480px) {
	.AlignAppHeader span {
    font-size: 24px;
  }

  .AlignAppHeader img {
    height: 36px;
  }
}