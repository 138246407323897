.EmailVerification {
    color: #ffffff;
}

.LoadingVerify {
    margin-top: 70px;
}

.LoadingVerify p {
    font-size: 18px;
}

.VerificationMsg {
    margin-top: 70px;
}

.VerificationMsg h1 {
    font-size: 22px;
}

.VericiationMsg p {
    font-size: 20px;
}

.SuccessfulVerify button, .AlreadyVerified button, .TokenDNE button {
    background-color: #CFD4E7;
    color: #272830;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    padding: 8px 50px;
    border: none;
    border-radius: 30px;
}

.SuccessfulVerify button:hover, .AlreadyVerified button:hover, .TokenDNE button:hover {
    background-color: #e9edfa;
    transition: all 0.2s ease;
    cursor: pointer;
}

/* Loading Animation CSS Animation taken from https://loading.io/css/ */
.LoadingAnimation {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 25px;
}

.LoadingAnimation div {
    position: absolute;
    top: 20px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.LoadingAnimation div:nth-child(1) {
    left: 6px;
    animation: LoadingAnimation1 0.6s infinite;
}

.LoadingAnimation div:nth-child(2) {
    left: 6px;
    animation: LoadingAnimation2 0.6s infinite;
}

.LoadingAnimation div:nth-child(3) {
    left: 24px;
    animation: LoadingAnimation2 0.6s infinite;
}

.LoadingAnimation div:nth-child(4) {
    left: 42px;
    animation: LoadingAnimation3 0.6s infinite;
}

@keyframes LoadingAnimation1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes LoadingAnimation3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes LoadingAnimation2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(18px, 0);
    }
}

@media screen and (max-width: 480px) {
    .VerificationMsg h1 {
        font-size: 18px;
    }
    
    .VericiationMsg p {
        font-size: 18px;
    }

    .VerificationMsg button {
        font-size: 16px;
        padding: 8px 40px;
    }
}